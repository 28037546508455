export const environment = {
  production: false,
  app: false,
  domains: {
    app: 'https://app-dev.schriftfuehrer.at',
    landing: 'https://dev.schriftfuehrer.at',
    functionStripe: '',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyB3SUvnFUJVzlg4eb2UnOjNFKCzOQQ8rDg',
    authDomain: 'schriftfu-dev.firebaseapp.com',
    projectId: 'schriftfu-dev',
    storageBucket: 'schriftfu-dev.appspot.com',
    messagingSenderId: '473112782076',
    appId: '1:473112782076:web:a9064556ea9c88e23fa379',
    measurementId: 'G-HV5GV35NKD',
  },
  features: {
    pagination: false,
    connectLandingAndApp: true,
  },
  planLimitsByGrantingLevel: {},
};
